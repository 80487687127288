:root {
  --white: #fff;
  --orange: #f36c00;
}

* {
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

img {
  max-width: 100%;
}

.outer-container {
  max-width: 800px;
  margin: auto;
  display: flex;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  /* box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.3); */
  overflow: hidden;
}

.outer-container > div {
  flex: 1;
  padding: 10px;
}

.outer-container .carousel-wrapper {
  /* background: var(--orange); */
  display: flex;
  flex-direction: column;
}
.carousel-wrapper {
  max-width: 140px;
}

.outer-container .carousel-wrapper p,
.carousel-item {
  font-weight: 700;
  font-size: 20px;
  color: var(--white);
  line-height: 1.3;
}

.outer-container .content {
  /* background: var(--white); */
}

.leading-text {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 6px;
}

.carousel {
  height: 100%;
  display: flex;
  flex: 5;

  align-items: center;
  /* color: var(--white); */
}

.carousel-button {
  display: flex;
  align-self: center;
  padding: 10px;
  max-width: 70px;
  /* border-radius: 30px; */
  /* background: var(--orange); */
  /* border: 2px solid var(--white); */
}

.carousel-button svg {
  height: 26px;
  width: 26px;
}
.carousel-button path {
  fill: var(--white);
}

.slides {
  align-self: flex-start;
  flex: 1;
  width: 100%;
  overflow: hidden;
}

.carousel-inner {
  position: relative;
  height: 420px;
}

.carousel-inner::before,
.carousel-inner::after {
  content: "";
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 110px;
  pointer-events: none;
}

.carousel-inner::before {
  top: 0;
}

.carousel-inner::after {
  bottom: 0;
}

.carousel-item {
  position: absolute;
  background: none;
  border: none;
  height: 110px;
  padding: 10;
  margin: 10;
  opacity: 0;
  /* top: 112px; */
  transition: transform 0.4s ease, opacity 0.4s ease;
}

.carousel-item.visible {
  opacity: 1;
}
