@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Montserrat:wght@500;700&family=Noto+Sans+Arabic:wght@300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "gilroy-bold";
  src: url("./assets/fonts/Gilroy/Gilroy-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: "gilroy";
  src: url("./assets/fonts/Gilroy/Gilroy-Light.otf") format("opentype");
}

@font-face {
  font-family: "gilroy-medium";
  src: url("./assets/fonts/Gilroy-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "gilroy-semibold";
  src: url("./assets/fonts/Gilroy-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: "gilroy-regular";
  src: url("./assets/fonts/Gilroy-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "gilroy-extrabold";
  src: url("./assets/fonts/Gilroy-Bold.ttf") format("opentype");
}
* {
  font-family: "gilroy", sans-serif;
}

.MuiInputBase-input {
  font-family: "gilroy", sans-serif;
  font-size: 16px !important;
  padding: 2px !important;
}
.MuiOutlinedInput-notchedOutline {
  border-radius: 10px !important;
}
.MuiFormLabel-root {
  font-family: "gilroy", sans-serif !important;
}

.MuiPaper-root {
  box-shadow: none !important;
  border-radius: 10px !important;
  padding: 0 20px;
}

.MuiDataGrid-panelWrapper {
  border: 1px solid #79797933;
  border-radius: 5px;
}

.MuiButtonBase-root {
  background-color: #efefef;
}

.MuiTableCell-root {
  border-color: #79797933 !important;
}

.OverlayContainer-container {
  background-color: #f8f9fa;
}
#react-select-5-listbox {
  z-index: 999;
  height: 100%;
  width: 100%;
  background-color: white !important;
}

.MuiToolbar-root {
  display: flex !important;
  justify-content: center !important ;
  padding: 5px 0 !important;
}
.TodayButton-button,
.NavigationButton-button,
.OpenButton-textButton {
  color: black !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}

.Cell-dayView {
  display: none;
}

.MuiPaper-root {
  padding: 0 !important;
  /* background-color: #f8f9fa !important; */
}

.MuiPaper-root {
  /* min-width:400px !important; */
}

.css-1nmdiq5-menu {
  z-index: 999 !important;
  background-color: white !important;
}

.MuiInputBase-root {
  min-height: 58px !important;
  padding: 0 10px;
}
/* 
.Mui-selected{
  background-color: black !important;
} */
.mantine-Paper-root {
  /* height: 100%; */
  overflow-y: auto;
}

.mantine-UnstyledButton-root {
  position: relative;
  /* top: 60px; */
  /* right: 50px; */
}

.mantine-Select-input {
  background-color: #f6f8f7;
  width: fit-content;
  border: none;
  width: 100%;
  height: 42px;
  font-size: 16px;
  color: #797979;
  font-weight: 600;
  border-radius: 10px;
  font-family: gilroy;
}

.mantine-Select-input:focus {
  border-color: black !important;
}

.MuiList-root {
  width: 100% !important;
  min-width: 150px;
}

.MuiMenuItem-root {
  font-family: gilroy;

  font-weight: 600 !important;
}
.MuiMenuItem-root:hover {
  background-color: #34b2c0 !important;
  color: white !important;
}

.MuiInputBase-root {
  padding: 0 25px !important;
}

.Mui-checked {
  color: #34b2c0 !important;
}

.MuiList-root {
  max-height: 300px;
  max-width: fit-content;
}

.PhoneInputCountryIconImg {
  height: 30px !important;
}

.custom-phone-input {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 10px;
  border: 1px solid #efefef;
  border-radius: 6px;
  background-color: white;
}

.custom-phone-input.error {
  border: 1px solid rgb(244 63 94);
}
.PhoneInputCountrySelect {
  height: 30px !important;
  width: 100px;
  font-size: 16px;
  border-radius: 5px;
  background-color: white;
}

.PhoneInputCountry {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-radius: 5px;
  gap: 5px;
}
.PhoneInputCountryIconImg {
  border-radius: 5px !important;
  border: none;
}

.PhoneInputInput {
  background-color: white;
  font-size: 16px;
  height: 40px;
  padding: 10px;
  width: 100%;
}

.PhoneInputInput::placeholder {
  color: #2f2f2f;
  font-size: 16px;
}

@media screen and (max-width: 769px) {
  .custom-phone-input {
    padding: 10px 5px;
  }

  .custom-phone-input.error {
    border: 1px solid rgb(244 63 94);
  }
  .PhoneInputCountrySelect {
    height: 30px !important;
    width: 20px !important;
  }

  .PhoneInputInput::placeholder {
    font-size: 16px;
  }
}

.mantine-Slider-markLabel {
  font-size: 16px !important;
}

@media screen and (max-width: 769px) {
  .mantine-Slider-markLabel {
    font-size: 16px !important;
  }
}
